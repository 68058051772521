import { Link } from "gatsby";
import React, { ReactElement } from "react";
import { Container } from "react-bootstrap";
import Layout from "../components/common/layout";

const NotFoundPage = (): ReactElement => (
  <Layout>
    <Container>
      <p>
        You just hit a route that doesn&#39;t exist. <Link to="/">Go back.</Link>
      </p>
    </Container>
  </Layout>
);

export default NotFoundPage;
